import styled, { css } from 'styled-components';
import { BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const Container = styled.div`
  min-width: 170px;

  //stacked
  ${({ stacked }) =>
    stacked &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    `}

  // slider
  ${({ stacked }) =>
    !stacked &&
    css`
      display: grid;
      grid-auto-flow: column;
      overflow-x: auto;
    `}

  // mobile only
  @media (min-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;

export const A = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;
  font-weight: bold;
  overflow: hidden;
  ${({ stacked }) =>
    !stacked &&
    css`
      width: 85px;
    `};
  ${({ active }) =>
    active &&
    css`
      border-bottom: 3px solid ${BRAND.primary};
    `}
`;

export const Label = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;
