import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FSBTheme } from 'Services/core__fsb-theme';
import Icons from 'UI/globals/Icons';
import {
  FSBCustomerSession,
  getIcon,
  GLOBAL_CONSTANTS,
  PubSub,
  PubsubEvents,
} from 'Services/core__services';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import { Translations } from '../core__feature-menu-app';
import {
  Container as Container_LOCAL,
  A as A_LOCAL,
  Label as Label_LOCAL,
} from 'UI/apps/FeatureMenuApp/FeatureMenu';
import {
  Container as Container_CORE,
  A as A_CORE,
  Label as Label_CORE,
} from 'CORE__UI/apps/FeatureMenuApp/core__feature-menu';
import { getComponent } from 'Services/core__imports';
import project from '../../../../project';

const Container = getComponent(Container_LOCAL, Container_CORE);
const A = getComponent(A_LOCAL, A_CORE);
const Label = getComponent(Label_LOCAL, Label_CORE);

const FeatureMenuReact = ({
  appConfig: {
    theme,
    stacked,
    items,
    requestURL,
    promotionurl,
    smallfeatureicon,
    showhidebasedonauth,
  },
}) => {
  const itemConfigs = {
    S: {
      icon: 'SPORTS',
      label: Translations.get('text.sports'),
      href: '/sportsbook/',
    },
    I: {
      icon: 'PLAY2',
      label: Translations.get('text.inplay'),
      href: '/all-in-play/',
    },
    P: {
      icon: 'PROMOTIONS',
      label: Translations.get('text.promos'),
      href: '/promotions/',
    },
    V: {
      icon: project.bespokeSvgSportsIcons ? 'VIRTUAL' : 'VIRTUALS',
      label: Translations.get('text.virtuals'),
      href: '/virtual/',
    },
    C: {
      name: 'casino',
      icon: 'CASINO',
      label: Translations.get('text.casino'),
      href: '/casino/',
    },
    K: {
      icon: 'CASINO',
      label: Translations.get('casino.category.list.livecasino'),
      href: '/casino/live-casino/',
    },
    L: {
      icon: 'LOTTO',
      label: Translations.get('text.lotto'),
      href: '/lotto/',
    },
    B: {
      icon: 'BETGAMES',
      label: Translations.get('text.betgames'),
      href: '/betgames/',
    },
    F: {
      icon: 'FANTASY2',
      label: Translations.get('text.fantasy'),
      href: '/fantasy/',
    },
  };
  const showHideBasedOnAuth = showhidebasedonauth?.split('');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Pubsub event listen for when the session is being updated
    const sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setLoggedIn(session.accessToken);
      }
    });

    // Pubsub event listen for when the session is being created
    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setLoggedIn(session.accessToken);
      }
    });

    // in the case a session isn't updated or created but page manages to change - check conditions on render
    const session = FSBCustomerSession.getSession();
    if (session) {
      setLoggedIn(session.accessToken);
    }

    return () => {
      sessionUpdated?.unsubscribe();
      sessionCreated?.unsubscribe();
    };
  }, []);

  const links = items
    .toUpperCase()
    .split('')
    .reduce((acc, id) => {
      const config = itemConfigs[id];

      if (!loggedIn && showHideBasedOnAuth?.includes(id)) {
        return acc;
      }

      if (config)
        return [
          ...acc,
          {
            ...config,
            active: requestURL.startsWith(config.href),
            href:
              config.href === GLOBAL_PATH.PROMOTIONS && promotionurl
                ? promotionurl
                : config.href,
          },
        ];

      return acc;
    }, []);

  if (links) {
    links.find(k => {
      if (k.name === GLOBAL_CONSTANTS.S_CASINO) {
        k.active =
          !requestURL.includes(GLOBAL_CONSTANTS.S_LIVE_CASINO) &&
          requestURL.startsWith(k.href);
      }
    });
  }

  return (
    <FSBTheme theme={theme}>
      <Container stacked={stacked}>
        {links.map(({ icon, label, href, active }) => (
          <A key={href} href={href} active={active} stacked={stacked}>
            {project.bespokeSvgSportsIcons ? (
              icon === GLOBAL_CONSTANTS.SPORTS ||
              icon === GLOBAL_CONSTANTS.VIRTUAL ? (
                <Icons
                  active={active}
                  category={
                    icon === GLOBAL_CONSTANTS.SPORTS
                      ? GLOBAL_CONSTANTS.SOCCER
                      : GLOBAL_CONSTANTS.VIRTUAL
                  }
                  bespokeSvgSportsIcons={project.bespokeSvgSportsIcons}
                  iconName={getIcon(icon)}
                />
              ) : icon === GLOBAL_CONSTANTS.FANTASY ? (
                <Icons
                  category={
                    icon === GLOBAL_CONSTANTS.FANTASY &&
                    GLOBAL_CONSTANTS.FANTASY
                  }
                  bespokeSvgSportsIcons={project.bespokeSvgSportsIcons}
                  iconName={getIcon(icon)}
                />
              ) : (
                <Icons iconName={getIcon(icon)} size={20} />
              )
            ) : (
              <Icons
                iconName={getIcon(icon)}
                size={smallfeatureicon ? 20 : 25}
              />
            )}

            <Label active={active}>{label}</Label>
          </A>
        ))}
      </Container>
    </FSBTheme>
  );
};

FeatureMenuReact.propTypes = {
  appConfig: PropTypes.shape({
    theme: PropTypes.string,
    stacked: PropTypes.bool,
    items: PropTypes.string,
    requestURL: PropTypes.string,
    promotionurl: PropTypes.string,
    smallfeatureicon: PropTypes.string,
    hidecasinoonlogout: PropTypes.bool,
    showhidebasedonauth: PropTypes.string,
  }),
};

export default FeatureMenuReact;
