import React from 'react';
import ReactDOM from 'react-dom';
import ScDecoder from 'Services/json/core__decoder';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { strToTranslate } from './core__feature-menu-translation';
import FeatureMenuReact from './component/core__feature-menu-react';

export const Translations = new TranslationsProvider('FeatureMenuApp');

class FeatureMenuApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;
    ReactDOM['render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    FeatureMenuReact.strToTranslate = strToTranslate;
    return FeatureMenuReact;
  }
}

export default FeatureMenuApp;
