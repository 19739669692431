import styled from 'styled-components';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';
import { Container, A } from 'CORE__UI/apps/FeatureMenuApp/core__feature-menu';
const Container_FR = styled(Container)`
  // mobile only
  @media (max-width: ${BREAKPOINT_L}) {
    display: grid;
  }
`;

const A_EX = styled(A)`
  // mobile only
  @media (max-width: ${BREAKPOINT_L}) {
    padding: 8px 0;
    font-size: 12px;
    width: 65px;
  }
`;
export { Container_FR as Container, A_EX as A };
