export const mockTranslations = {
  'text.sports': 'Sports',
  'text.inplay': 'In-Play',
  'text.promos': 'Promos',
  'text.virtuals': 'Virtuals',
  'text.casino': 'Casino',
  'text.lotto': 'Lotto',
  'text.betgames': 'Betgames',
  'casino.category.list.livecasino': 'Live Casino',
  'text.fantasy': 'Fantasy',
};

export const translationKeys = Object.keys(mockTranslations);
